// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-amx-story-index-js": () => import("./../../../src/pages/about/amx-story/index.js" /* webpackChunkName: "component---src-pages-about-amx-story-index-js" */),
  "component---src-pages-about-careers-index-js": () => import("./../../../src/pages/about/careers/index.js" /* webpackChunkName: "component---src-pages-about-careers-index-js" */),
  "component---src-pages-about-contact-us-index-jsx": () => import("./../../../src/pages/about/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-about-contact-us-index-jsx" */),
  "component---src-pages-about-get-in-touch-index-jsx": () => import("./../../../src/pages/about/get-in-touch/index.jsx" /* webpackChunkName: "component---src-pages-about-get-in-touch-index-jsx" */),
  "component---src-pages-articles-strapi-insights-slug-jsx": () => import("./../../../src/pages/articles/{StrapiInsights.slug}.jsx" /* webpackChunkName: "component---src-pages-articles-strapi-insights-slug-jsx" */),
  "component---src-pages-best-execution-order-handling-jsx": () => import("./../../../src/pages/best-execution-order-handling.jsx" /* webpackChunkName: "component---src-pages-best-execution-order-handling-jsx" */),
  "component---src-pages-brochures-strapi-case-studies-slug-jsx": () => import("./../../../src/pages/brochures/{StrapiCaseStudies.slug}.jsx" /* webpackChunkName: "component---src-pages-brochures-strapi-case-studies-slug-jsx" */),
  "component---src-pages-case-studies-strapi-case-studies-slug-jsx": () => import("./../../../src/pages/case-studies/{StrapiCaseStudies.slug}.jsx" /* webpackChunkName: "component---src-pages-case-studies-strapi-case-studies-slug-jsx" */),
  "component---src-pages-conflicts-of-interest-disclosure-statement-jsx": () => import("./../../../src/pages/conflicts-of-interest-disclosure-statement.jsx" /* webpackChunkName: "component---src-pages-conflicts-of-interest-disclosure-statement-jsx" */),
  "component---src-pages-events-strapi-events-slug-jsx": () => import("./../../../src/pages/events/{StrapiEvents.slug}.jsx" /* webpackChunkName: "component---src-pages-events-strapi-events-slug-jsx" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-guides-strapi-case-studies-slug-jsx": () => import("./../../../src/pages/guides/{StrapiCaseStudies.slug}.jsx" /* webpackChunkName: "component---src-pages-guides-strapi-case-studies-slug-jsx" */),
  "component---src-pages-important-notice-jsx": () => import("./../../../src/pages/important-notice.jsx" /* webpackChunkName: "component---src-pages-important-notice-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-case-studies-index-js": () => import("./../../../src/pages/insights/case-studies/index.js" /* webpackChunkName: "component---src-pages-insights-case-studies-index-js" */),
  "component---src-pages-insights-events-index-js": () => import("./../../../src/pages/insights/events/index.js" /* webpackChunkName: "component---src-pages-insights-events-index-js" */),
  "component---src-pages-insights-latest-insights-index-jsx": () => import("./../../../src/pages/insights/latest-insights/index.jsx" /* webpackChunkName: "component---src-pages-insights-latest-insights-index-jsx" */),
  "component---src-pages-insights-press-release-index-js": () => import("./../../../src/pages/insights/press-release/index.js" /* webpackChunkName: "component---src-pages-insights-press-release-index-js" */),
  "component---src-pages-legal-and-regulatory-jsx": () => import("./../../../src/pages/legal-and-regulatory.jsx" /* webpackChunkName: "component---src-pages-legal-and-regulatory-jsx" */),
  "component---src-pages-legal-strapi-legal-pages-slug-jsx": () => import("./../../../src/pages/legal/{StrapiLegalPages.slug}.jsx" /* webpackChunkName: "component---src-pages-legal-strapi-legal-pages-slug-jsx" */),
  "component---src-pages-notes-to-editors-jsx": () => import("./../../../src/pages/notes-to-editors.jsx" /* webpackChunkName: "component---src-pages-notes-to-editors-jsx" */),
  "component---src-pages-press-release-strapi-press-release-slug-jsx": () => import("./../../../src/pages/press-release/{StrapiPressRelease.slug}.jsx" /* webpackChunkName: "component---src-pages-press-release-strapi-press-release-slug-jsx" */),
  "component---src-pages-preview-homepage-js": () => import("./../../../src/pages/preview/homepage.js" /* webpackChunkName: "component---src-pages-preview-homepage-js" */),
  "component---src-pages-privacy-notice-jsx": () => import("./../../../src/pages/privacy-notice.jsx" /* webpackChunkName: "component---src-pages-privacy-notice-jsx" */),
  "component---src-pages-reg-amx-ucits-ccf-storebrand-global-esg-plus-jsx": () => import("./../../../src/pages/reg/amx-ucits-ccf-storebrand-global-esg-plus.jsx" /* webpackChunkName: "component---src-pages-reg-amx-ucits-ccf-storebrand-global-esg-plus-jsx" */),
  "component---src-pages-reg-axa-investment-managers-long-term-credit-jsx": () => import("./../../../src/pages/reg/axa-investment-managers-long-term-credit.jsx" /* webpackChunkName: "component---src-pages-reg-axa-investment-managers-long-term-credit-jsx" */),
  "component---src-pages-reg-axa-investment-managers-single-investment-long-term-credit-jsx": () => import("./../../../src/pages/reg/axa-investment-managers-single-investment-long-term-credit.jsx" /* webpackChunkName: "component---src-pages-reg-axa-investment-managers-single-investment-long-term-credit-jsx" */),
  "component---src-pages-reg-ccf-metropolis-value-fund-jsx": () => import("./../../../src/pages/reg/ccf-metropolis-value-fund.jsx" /* webpackChunkName: "component---src-pages-reg-ccf-metropolis-value-fund-jsx" */),
  "component---src-pages-reg-ccf-sands-global-growth-jsx": () => import("./../../../src/pages/reg/ccf-sands-global-growth.jsx" /* webpackChunkName: "component---src-pages-reg-ccf-sands-global-growth-jsx" */),
  "component---src-pages-reg-ccf-veritas-global-focus-jsx": () => import("./../../../src/pages/reg/ccf-veritas-global-focus.jsx" /* webpackChunkName: "component---src-pages-reg-ccf-veritas-global-focus-jsx" */),
  "component---src-pages-reg-disclosure-amx-ucits-ccf-stoxx-willis-towers-watson-world-climate-transition-index-jsx": () => import("./../../../src/pages/reg/disclosure-amx-ucits-ccf-stoxx-willis-towers-watson-world-climate-transition-index.jsx" /* webpackChunkName: "component---src-pages-reg-disclosure-amx-ucits-ccf-stoxx-willis-towers-watson-world-climate-transition-index-jsx" */),
  "component---src-pages-reg-fund-disclosure-mba-ucits-ccf-jsx": () => import("./../../../src/pages/reg/fund-disclosure-mba-ucits-ccf.jsx" /* webpackChunkName: "component---src-pages-reg-fund-disclosure-mba-ucits-ccf-jsx" */),
  "component---src-pages-reg-sands-emerging-markets-jsx": () => import("./../../../src/pages/reg/sands-emerging-markets.jsx" /* webpackChunkName: "component---src-pages-reg-sands-emerging-markets-jsx" */),
  "component---src-pages-reg-systematica-equity-factor-jsx": () => import("./../../../src/pages/reg/systematica-equity-factor.jsx" /* webpackChunkName: "component---src-pages-reg-systematica-equity-factor-jsx" */),
  "component---src-pages-reg-versor-systematic-merger-arbitrage-jsx": () => import("./../../../src/pages/reg/versor-systematic-merger-arbitrage.jsx" /* webpackChunkName: "component---src-pages-reg-versor-systematic-merger-arbitrage-jsx" */),
  "component---src-pages-remuneration-policy-jsx": () => import("./../../../src/pages/remuneration-policy.jsx" /* webpackChunkName: "component---src-pages-remuneration-policy-jsx" */),
  "component---src-pages-reports-strapi-case-studies-slug-jsx": () => import("./../../../src/pages/reports/{StrapiCaseStudies.slug}.jsx" /* webpackChunkName: "component---src-pages-reports-strapi-case-studies-slug-jsx" */),
  "component---src-pages-shareholder-rights-directive-ii-disclosure-jsx": () => import("./../../../src/pages/shareholder-rights-directive-II-disclosure.jsx" /* webpackChunkName: "component---src-pages-shareholder-rights-directive-ii-disclosure-jsx" */),
  "component---src-pages-solutions-additional-services-client-experience-index-js": () => import("./../../../src/pages/solutions/additional-services/client-experience/index.js" /* webpackChunkName: "component---src-pages-solutions-additional-services-client-experience-index-js" */),
  "component---src-pages-solutions-additional-services-fund-structures-index-js": () => import("./../../../src/pages/solutions/additional-services/fund-structures/index.js" /* webpackChunkName: "component---src-pages-solutions-additional-services-fund-structures-index-js" */),
  "component---src-pages-solutions-additional-services-independent-risk-oversight-index-js": () => import("./../../../src/pages/solutions/additional-services/independent-risk-oversight/index.js" /* webpackChunkName: "component---src-pages-solutions-additional-services-independent-risk-oversight-index-js" */),
  "component---src-pages-solutions-additional-services-money-market-funds-index-js": () => import("./../../../src/pages/solutions/additional-services/money-market-funds/index.js" /* webpackChunkName: "component---src-pages-solutions-additional-services-money-market-funds-index-js" */),
  "component---src-pages-solutions-amxconnect-overview-index-js": () => import("./../../../src/pages/solutions/amxconnect-overview/index.js" /* webpackChunkName: "component---src-pages-solutions-amxconnect-overview-index-js" */),
  "component---src-pages-solutions-asset-managers-index-js": () => import("./../../../src/pages/solutions/asset-managers/index.js" /* webpackChunkName: "component---src-pages-solutions-asset-managers-index-js" */),
  "component---src-pages-solutions-consultants-index-js": () => import("./../../../src/pages/solutions/consultants/index.js" /* webpackChunkName: "component---src-pages-solutions-consultants-index-js" */),
  "component---src-pages-solutions-fund-solutions-index-js": () => import("./../../../src/pages/solutions/fund-solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-fund-solutions-index-js" */),
  "component---src-pages-solutions-investor-stewardship-service-index-js": () => import("./../../../src/pages/solutions/investor-stewardship-service/index.js" /* webpackChunkName: "component---src-pages-solutions-investor-stewardship-service-index-js" */),
  "component---src-pages-solutions-investors-insurance-company-index-js": () => import("./../../../src/pages/solutions/investors/insurance-company/index.js" /* webpackChunkName: "component---src-pages-solutions-investors-insurance-company-index-js" */),
  "component---src-pages-solutions-investors-multinational-pensions-index-js": () => import("./../../../src/pages/solutions/investors/multinational-pensions/index.js" /* webpackChunkName: "component---src-pages-solutions-investors-multinational-pensions-index-js" */),
  "component---src-pages-solutions-investors-pension-schemes-index-js": () => import("./../../../src/pages/solutions/investors/pension-schemes/index.js" /* webpackChunkName: "component---src-pages-solutions-investors-pension-schemes-index-js" */),
  "component---src-pages-solutions-platform-solutions-index-js": () => import("./../../../src/pages/solutions/platform-solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-platform-solutions-index-js" */),
  "component---src-pages-solutions-service-partners-index-js": () => import("./../../../src/pages/solutions/service-partners/index.js" /* webpackChunkName: "component---src-pages-solutions-service-partners-index-js" */),
  "component---src-pages-solutions-third-party-management-company-index-js": () => import("./../../../src/pages/solutions/third-party-management-company/index.js" /* webpackChunkName: "component---src-pages-solutions-third-party-management-company-index-js" */),
  "component---src-pages-sustainable-finance-disclosure-regulation-jsx": () => import("./../../../src/pages/sustainable-finance-disclosure-regulation.jsx" /* webpackChunkName: "component---src-pages-sustainable-finance-disclosure-regulation-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-pages-ucits-disclaimer-jsx": () => import("./../../../src/pages/ucits/disclaimer.jsx" /* webpackChunkName: "component---src-pages-ucits-disclaimer-jsx" */),
  "component---src-pages-ucits-jsx": () => import("./../../../src/pages/ucits.jsx" /* webpackChunkName: "component---src-pages-ucits-jsx" */),
  "component---src-pages-videos-strapi-insights-slug-jsx": () => import("./../../../src/pages/videos/{StrapiInsights.slug}.jsx" /* webpackChunkName: "component---src-pages-videos-strapi-insights-slug-jsx" */),
  "component---src-templates-ucits-jsx": () => import("./../../../src/templates/Ucits.jsx" /* webpackChunkName: "component---src-templates-ucits-jsx" */)
}

